/* eslint-disable import/no-cycle */
import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Spin,
  message
} from 'antd';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import PhasesFormArray from './PhasesFormArray';
import QuotaFormArray from './QuotasFormArray';

import HeaderPage from 'common/components/HeaderPage';
import { getTopupConfigService, saveTopupConfigService } from 'common/services/configPrizes';
import { topupConfigSchema } from 'common/utils/schemas';

export type TopupConfigForm = {
  quotas: {
    quota: number
    quotaDate?: string
    probability: number
  }[]
  phases: {
    phase: number
    from: string
    to: string
    prizesPerPhone: number
    amount: number
    quantity: number
  }[]
};

const ConfigTopup: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate }) => {
  /* Hooks */
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  /* Variables */
  const editable = roleCreate || roleUpdate;
  const method = useForm<TopupConfigForm>({
    resolver: yupResolver(topupConfigSchema),
    defaultValues: {
      quotas: [
        {
          quota: 0,
          probability: 0,
        }
      ],
      phases: [{
        phase: 1
      }]
    }
  });
  /* Queries */
  const { data: topupConfigData, isLoading } = useQuery('config-topup', getTopupConfigService);

  const { mutate: saveMutate, isLoading: saveLoading } = useMutation(
    'saveTopupConfig',
    saveTopupConfigService,
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries('config-topup');
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  const handleSave = async () => {
    const valid = await method.trigger();

    if (valid) {
      const data = method.getValues();
      const { quotas, phases } = data;
      saveMutate({
        quotas: quotas.map((item) => ({
          quota: Number(item.quota),
          quotaDate: item.quotaDate
        })),
        probabilities: quotas.map((item) => ({
          type: 'percent',
          quotaDate: item.quotaDate,
          fromHour: '00:00:00',
          toHour: '23:59:59',
          probability: Number(item.probability),
          quota: 100
        })),
        phases
      });
    }
  };

  useEffect(() => {
    if (!topupConfigData) return;
    method.reset({
      quotas: topupConfigData && topupConfigData.quotas.length > 0
        ? topupConfigData.quotas.map((item) => ({
          quota: item.quota,
          quotaDate: item.quotaDate,
          probability: topupConfigData.probabilities.find((
            prob
          ) => prob.quotaDate === item.quotaDate)?.probability || 0
        })) : [{
          quota: 0,
          probability: 0
        }],
      phases: topupConfigData && topupConfigData.phases.length > 0
        ? topupConfigData.phases.map((item) => ({
          phase: item.phase,
          from: new Date(item.from).toISOString(),
          to: new Date(item.to).toISOString(),
          prizesPerPhone: item.prizesPerPhone,
          amount: item.amount,
          quantity: item.quantity,
        })) : [{
          phase: 1
        }]
    });
  }, [method, topupConfigData]);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.configTopup')}
        rightHeader={(
          <Button
            type="primary"
            loading={saveLoading}
            disabled={!editable}
            onClick={handleSave}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        )}
      />
      <Spin size="large" spinning={isLoading}>
        <div className="t-mainlayout_wrapper">
          <FormProvider {...method}>
            <Card title={t('topupConfig.quotas')}>
              <QuotaFormArray
                editable={editable}
                method={method}
              />
            </Card>
            <Card title={t('topupConfig.phase')} className="u-mt-16">
              <PhasesFormArray
                editable={editable}
                method={method}
              />
            </Card>
          </FormProvider>
        </div>
      </Spin>
    </>
  );
};

export default ConfigTopup;
